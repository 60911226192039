import React from 'react'
import './SponsorsMain.css'

function SponsorsMain() {
  return (
    <div className="SponsorsMain">
      <header className="section-header">
          <div className="center">
            <h2 className="section-title">
             Our Collaborations<br />
             
            </h2>
          </div>
        </header>
    <div className="scrollbox scrollbox--primary">
  <div className="scrollbox__item"><img src="adidas.png" alt="" /></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="google.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="nike.png" alt="" /></div>
</div>

<div className="scrollbox scrollbox--secondary scrollbox--reverse">
<div className="scrollbox__item"><img src="adidas.png" alt="" /></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="facebook.png" alt="logo"/></div>
  <div className="scrollbox__item"><img src="google.png" alt="" /></div>
  <div className="scrollbox__item"><img src="instagram.png" alt="" /></div>
  <div className="scrollbox__item"><img src="nike.png" alt="" /></div>
</div>

    </div>
  )
}

export default SponsorsMain